<script>
    export let buttonSize = 'btn-sm';
    export let textSize = 'text-xs';
    export let buildingId = '';
    export let buildingName = '';
    export let classes = '';
    export let inverted = false;
    export let isMobile = false;

    let href = `/add-review`;
    $: {
        if (buildingId && buildingId.length > 0) {
            href = `/add-review?b_id=${buildingId}`;
        }
    }

    let buttonText = 'Add a Review'
    $: {
        if (buildingName && buildingName.length > 0) {
            buttonText = `Add a Review for ${isMobile && buildingName.length > 20 ? '<br />' : ''}${buildingName}`
        }
    }
</script>

<a class={`btn ${buttonSize} ${textSize} ${inverted ? 'bg-white text-black': 'cta text-white'} rounded-lg ${classes}`} href={href}>
    {@html buttonText}
</a>